<template>
  <date-picker
    append-to-body
    v-bind="$attrs"
    :inline="inline"
    :format="format"
    :value-type="localValueType"
    :time-title-format="format"
    :disabled-date="localDisabledDate"
    :class="[`v-input-date`, isError && `v-input-date--error`]"
    v-on="$listeners"
  >
    <template #icon-calendar>
      <slot name="icon-calendar"><v-icon-calendar /></slot>
    </template>
    <template #input>
      <slot name="input"></slot>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import VIconCalendar from '@/components/icons/VCalendar.vue'

import 'vue2-datepicker/locale/ru'

export default {
  name: 'VInputDate',
  components: {
    DatePicker,
    VIconCalendar
  },
  inheritAttrs: false,
  props: {
    // Props for datapicker see here - https://github.com/mengxiong10/vue2-datepicker#props
    format: {
      type: String,
      default: 'DD.MM.YY'
    },
    valueType: {
      type: String,
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    isError: { type: Boolean, default: false },
    disabledDate: {
      type: Function,
      default: null
    },
    isDisabledDatesAfterCurrentDate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValueType() {
      return this.valueType ? this.valueType : this.format
    }
  },
  methods: {
    disabledDatesAfterCurrentDate(date) {
      return date > new Date()
    },
    localDisabledDate(date) {
      if (this.disabledDate) return this.disabledDate()
      if (this.isDisabledDatesAfterCurrentDate) return this.disabledDatesAfterCurrentDate(date)
      return false
    }
  }
}
</script>

<template>
  <!-- eslint-disable vue/max-len -->
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.667 2.667H12V1.333h-1.333v1.334H5.333V1.333H4v1.334h-.667c-.74 0-1.326.6-1.326 1.333L2 13.333c0 .734.593 1.334 1.333 1.334h9.334c.733 0 1.333-.6 1.333-1.334V4c0-.733-.6-1.333-1.333-1.333zm-8 4.666v1.334H6V7.333H4.667zm4 0H7.333v1.334h1.334V7.333zm1.333 0h1.333v1.334H10V7.333zm-6.667 6h9.334V6H3.333v7.333z"
      fill="#404042"
    />
  </svg>
</template>

<script>
export default {
  name: 'VCalendar'
}
</script>
